
import baseConstants from '~/store/base/-constants'

export default {
  name: 'DefaultPage',
  metaInfo() {
    return {
      title: this.$store.state.base.meta.generalConfig.baseTitle,
      titleTemplate: `${this.$store.state.base.meta.generalConfig.baseTitle} - ${this.meta.title}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.meta.description
        }
      ]
    }
  },
  data() {
    return {
      sections: []
    }
  },
  head() {
    return {
      title: this.$store.state.base.meta.generalConfig.baseTitle,
      titleTemplate: `${this.$store.state.base.meta.generalConfig.baseTitle} - ${this.meta.title}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.meta.description
        }
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.$store.state.base.meta.generalConfig.favicon
        }
      ]
    }
  },
  computed: {
    currentPage() {
      const projectStaticPages = this.$store.state.base.meta.generalConfig.projectStaticPages
      const path = this.$route.path
      const lastPathElement = path.split('/').pop()
      return projectStaticPages.find((p) => p.path === '/' + lastPathElement)
    },
    meta() {
      if (!this.currentPage) return {}
      return this.currentPage.meta
    },
    sectionsData() {
      if (!this.currentPage) return []
      return [...this.currentPage.sections]
    }
  },
  created() {
    this.sections = this.sectionsData.sort((a, b) => {
      if (a.order_no < b.order_no) {
        return -1
      }
      if (a.order_no > b.order_no) {
        return 1
      }
      return 0
    })
  },
  beforeMount() {
    if (this.$store.state.base.documentIsReady) {
      const base = this.$store.getters.constants.base
      this.$store.dispatch(base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE), false)
    }
  },
  mounted() {
    this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_SIDEBAR_STATE))
  }
}
